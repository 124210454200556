import React from 'react'
import { Icon } from '@pff-consumer/web-ui/components/icon/icon'
import { ButtonProps } from './button.props'
import { useStyles } from './button.styles'
import { CircleTimer } from './circle-loader/circle-loader'

export const Button = (props: ButtonProps) => {
  const {
    type,
    onClick,
    iconGroup,
    iconName,
    iconWidth,
    iconHeight,
    disabled = false,
    isPending,
    text = '',
    className,
    variant,
    size = 'lg',
    ...other
  } = props
  const styles = useStyles(props)

  const isLargeBtn = size === 'lg'

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      className={`${styles.button} ${styles.buttonText} ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      {isPending && (
        <div className={styles.spinnerContainer}>
          <CircleTimer variant={variant === 'Apple' && isLargeBtn ? variant : 'Loading'} />
        </div>
      )}

      {iconName && iconGroup ? (
        <span className={isLargeBtn ? styles.buttonLogo : styles.buttonLogo}>
          <Icon
            name={iconName}
            group={iconGroup}
            width={iconWidth}
            height={iconHeight}
          />
        </span>
      ) : null}
      {isLargeBtn && text}
    </button>
  )
}
