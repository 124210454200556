import { memo, useRef } from 'react'
import { CircleLoaderProps } from './circle-loader.props'
import { useStyles } from './circle-loader.styles'

export const CircleTimer = memo((props: CircleLoaderProps) => {
  const svgRef = useRef(null)
  const styles = useStyles(props)

  return (
    <div className={styles.container}>
      <svg
        aria-hidden='true'
        className={styles.icon}
        ref={svgRef}
        viewBox='0 0 100 100'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          className={styles.iconBase}
          r='40'
          cy='50'
          cx='50'
          fill='none'
        />
        <circle
          className={styles.iconFill}
          r='40'
          cy='50'
          cx='50'
          fill='none'
        />
      </svg>
    </div>
  )
})
CircleTimer.displayName = 'CircleTimer'
