import React, { memo, KeyboardEvent, useEffect } from 'react'
import { Icon } from '@pff-consumer/web-ui/components/icon/icon'
import { SVGGroup } from '@pff-consumer/utils'
import { useStyles } from './modal.styles'
import { ModalProps } from './modal.props'
import { Button } from '../button/button'

export const Modal = memo((props: ModalProps) => {
  const { onClose, closeable, description, heading, buttonText, handleButtonClick, showModal } = props
  const styles = useStyles()

  // Let Escape close the modal
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showModal && closeable) {
      const handleEscape = (event: KeyboardEvent) => {
        if (event?.key === 'Escape') {
          onClose()
        }
      }
      // @ts-ignore
      document.addEventListener('keydown', handleEscape)

      return () => {
        // @ts-ignore
        document.removeEventListener('keydown', handleEscape)
      }
    }
  }, [onClose, showModal, closeable])

  if (!showModal) return null

  return (
    <div
      className={styles.container}
      role='dialog'
    >
      {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.overlay}
        onClick={closeable ? onClose : undefined}
      />

      {closeable && (
        <button
          onClick={onClose}
          type='button'
          className={styles.closeButton}
          aria-label='close modal'
          data-analytics-id='error-modal-close'
        >
          <Icon
            name='close'
            testID='close-icon'
            group={SVGGroup.Icons}
            width={24}
            height={24}
            className={styles.closeIcon}
          />
        </button>
      )}

      <div className={styles.drawerContainer}>
        <div className={styles.drawer}>
          <div className={styles.heading}>{heading}</div>
          <div className={styles.modelText}>{description}</div>
          <div>
            <Button
              text={buttonText}
              variant='Primary'
              onClick={handleButtonClick}
              data-analytics-id='error-modal-cta'
            />
          </div>
        </div>
      </div>
    </div>
  )
})
