import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'
import { CircleLoaderProps } from './circle-loader.props'

interface CircleLoaderStyles {
  container: Styles
  icon: Styles
  iconFill: Styles
  iconBase: Styles
}

export const useStyles = createUseStyles<CircleLoaderStyles, CircleLoaderProps>((theme) => {
  const diameter = 40 * 2 * 3.1415

  return {
    container: ({ variant }) => ({
      width: theme.calcUnit(22),
      height: theme.calcUnit(22),
      marginRight: variant === 'Apple' ? '40%' : theme.calcUnit(theme.spacing.s2),
    }),
    icon: {
      display: 'block',
      transform: 'rotate(-90deg)',
    },
    iconFill: {
      animationDuration: 2000,
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
      backfaceVisibility: 'hidden',
      strokeDasharray: diameter,
      strokeDashoffset: diameter,
      strokeWidth: theme.calcUnit(20),
      animationName: '$timer_animation_infinite',
      animationIterationCount: 'infinite',
      stroke: 'white',
    },
    iconBase: {
      backfaceVisibility: 'hidden',
      strokeWidth: theme.calcUnit(20),
      stroke: '#B3B3B3', // gray-500
    },
    '@keyframes timer_animation_infinite': {
      '0%': {
        strokeDashoffset: diameter,
      },
      '70%': {
        strokeDashoffset: 0,
        opacity: 1,
      },
      '75%': {
        strokeDashoffset: 0,
        opacity: 1,
      },
      '90%': {
        strokeDashoffset: 0,
        opacity: 0,
      },
      '100%': {
        strokeDashoffset: 0,
        opacity: 0,
      },
    },
  }
})
