import { checkoutHooks } from '@pff-consumer/data-provider/lib/checkout/checkout.hooks'
import { useSubscribeAnalyticsEvent } from '../../hooks/useSubscribAnalyticsEvent'

const { useEmail } = checkoutHooks

export const withErrorBoundary = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => {
    const { sendSubscribeAnalyticsEvent } = useSubscribeAnalyticsEvent()
    const email = useEmail()

    return (
      <Component
        email={email}
        sendSubscribeAnalyticsEvent={sendSubscribeAnalyticsEvent}
        {...props}
      />
    )
  }
}
