import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface ModelStyles {
  closeButton: Styles
  container: Styles
  closeIcon: Styles
  drawer: Styles
  drawerContainer: Styles
  header: Styles
  heading: Styles
  modelText: Styles
  overlay: Styles
}

export const useStyles = createUseStyles<ModelStyles>((theme): ModelStyles => {
  return {
    container: {
      bottom: '0',
      display: 'flex',
      flexFlow: 'column',
      left: '0',
      position: 'fixed',
      right: '0',
      top: '0',
      visibility: 'visible',
      zIndex: '10000',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.9)',
      bottom: '0',
      display: 'flex',
      flexFlow: 'column',
      left: '0',
      opacity: '1',
      position: 'fixed',
      right: '0',
      top: '0',
    },
    heading: {
      fontFamily: 'PFF Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: theme.calcUnit(20),
      lineHeight: theme.calcUnit(24),
    },
    modelText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: theme.calcUnit(16),
      lineHeight: theme.calcUnit(24),
      marginTop: theme.calcUnit(theme.spacing.s2),
      marginBottom: theme.calcUnit(theme.spacing.s4),
    },
    closeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: theme.calcUnit(32),
      width: theme.calcUnit(32),
      backgroundColor: '#333333',
      borderRadius: theme.calcUnit(theme.borderRadius.roundedFull),
      zIndex: '10000',
      cursor: 'pointer',
      position: 'absolute',
      top: theme.calcUnit(16),
      right: theme.calcUnit(16),
    },
    closeIcon: {
      position: 'absolute',
    },
    drawerContainer: {
      height: '100%',
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
    },
    drawer: {
      position: 'relative',
      margin: theme.calcUnit(theme.spacing.s4),
      padding: theme.calcUnit(theme.spacing.s4),
      background: theme.colors.backgroundColor,
      borderRadius: theme.calcUnit(theme.borderRadius.roundedDefault),
      ...theme.breakpoint.up(472, {
        margin: `${theme.calcUnit(theme.spacing.s4)} auto`,
        maxWidth: theme.calcUnit(440),
      }),
    },
    header: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '900',
      fontSize: theme.calcUnit(28),
      lineHeight: theme.calcUnit(36),
      marginTop: theme.calcUnit(theme.spacing.s4),
      paddingBottom: theme.calcUnit(theme.spacing.s4),
    },
  }
})
