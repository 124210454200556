import React from 'react'
import * as Sentry from '@sentry/nextjs'
import { AnalyticsEventArgs } from '@pff-consumer/analytics'
import { Modal } from '../common/modal/modal'
import { withErrorBoundary } from './with-error-boundary'

interface Props {
  children?: React.ReactNode
  sendSubscribeAnalyticsEvent: (...args: AnalyticsEventArgs) => void
  email: string
}

interface State {
  error: Error | null
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: Error) {
    const { email, sendSubscribeAnalyticsEvent } = this.props

    this.setState({
      error,
    })
    Sentry.captureException(error)
    sendSubscribeAnalyticsEvent('App-wide Error', {
      email,
      error: `${error.message} ${error.stack}`,
      error_type: 'ERROR_BOUNDARY',
    })
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return (
        <Modal
          closeable={false}
          heading='FLAG ON THE FIELD!'
          description='PFF.com is experiencing some technical difficulties.'
          buttonText='Reload'
          handleButtonClick={() => window.location.reload()}
          onClose={() => {}}
          showModal
        />
      )
    }

    return children
  }
}

// eslint-disable-next-line import/no-default-export
export default withErrorBoundary(ErrorBoundary)
